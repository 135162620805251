import { Actions } from './permissions';

export interface Permission {
    id: number,
    name: string;
    value: string;
}

export interface Role {
    id: number;
    name: string;
    authority: string;
}

export interface Company {
    id: number;
    name: string;
    size: string;
}

export enum UserStatuses {
    INVITED = 'INVITED',
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED',
}

export interface User {
    id: number;
    fullName: string;
    email: string;
    jobTitle: string;
    roles: Role[];
    companyId?: number;
    product: Product[];
    status: UserStatuses;
    teams: Team[];
    userName?: string | null
}

export interface UserSaveModel extends Omit<User, 'id' | 'email' | 'roles' | 'companyId' | 'product' | 'status' | 'teams'> {
    role: string;
}

// export interface Feature {
//     id: number;
//     name: string;
// }

export interface TeamGroup {
    id: number,
    name: string
}

export interface Team {
    id: number;
    name: string;
    lead: User | null;
    products: Product[]
    users: User[];
    group: TeamGroup;
}

export interface TeamSaveModel {
    name: string;
    // features: Feature[];
    userIds: number[];
    productIds: number[];
    lead: number | null
    group: number | null
}

export interface SidebarNestedRoute {
    url: string;
    component: () => JSX.Element;
}

interface SidebarMenuItemBase {
    url?: string;
    title: string | JSX.Element;
    icon?: () => JSX.Element;
    nestedRoutes?: SidebarNestedRoute[];
    onBottom?: boolean;
    subMenus?: SidebarMenuItem[];
    permission?: Actions;
    hidden?: boolean;
    paramId?: string
    sections?: {
        url: string,
        title: string,
        component: () => JSX.Element,
    }[]
    beta?: boolean
    featureFlag?: string;
    isEnabled?: boolean
}

interface SidebarMenuItemWithoutPopup extends SidebarMenuItemBase {
    component: React.ComponentType<{}>;
    hasPopupMenu?: false;
}


interface SidebarMenuItemWithPopup extends SidebarMenuItemBase {
    component: React.ComponentType<SidebarPopupMenuProp>;
    hasPopupMenu: true
}

export type SidebarMenuItem = SidebarMenuItemWithoutPopup | SidebarMenuItemWithPopup

export interface SidebarPopupMenuProp {
    anchorEl: HTMLElement | null;
    setAnchorEl: (anchorEl: HTMLElement | null) => void;
}

export interface Product {
    id: number;
    title: string;
    owner: Owner;
    parentProduct: ParentProduct | null;
    teams: Team[];
    blocks?: Block[];
}

export interface ProductSaveModel extends Omit<Product, 'owner' | 'parentProduct' | 'id' | 'teams'> {
    parentProduct: number | null;
    owner: number;
    teams: number[];
}

export interface Owner {
    id: number;
    fullName: string;
}

export interface ParentProduct {
    id: number;
    title: string;
}


export interface ProductInitiative extends Omit<Initiative, 'problem' | 'solution' | 'validation' | 'impact'> {
}

export interface ProductInitiatives {
    childProductInitiatives: ChildProductInitiative[];
    affectedProductInitiatives: ChildProductInitiative[];
    selfInitiatives: ProductInitiative[]
}

export interface ChildProductInitiative {
    product: {
        id: number;
        title: string;
    };
    initiatives: ProductInitiative[]
}

export enum InitiativeStatus {
    'Backlog' = 'Backlog',
    'Conception' = 'Conception',
    'Research' = 'Research',
    'Design' = 'Design',
    'Requirement Gathering' = 'RequirementGathering',
    'Ready for Development' = 'ReadyForDevelopment',
    'In Development' = 'Development',
    'In testing' = 'InTesting',
    'Development done' = 'DevelopmentDone',
    'In Release' = 'Release',
    'A/B Testing' = 'ABTesting',
    'Live' = 'Live',
    'Blocked' = 'Blocked',
    'Sunsetted' = 'Sunsetted',
    'Canceled' = 'Canceled',
    'AI in Progress' = 'AiInProgress',
    'Paused' = 'Paused'

}

export enum InitiativePriority {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Critical = 'Critical',
}
export enum InitiativeType {
    'New Feature' = 'NewProduct',
    'Improvement' = 'Improvement',
}

export enum InitiativeSize {
    // XS = 'XS',
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
}

export enum BlockTypes {
    Attachment = 'attachment',
    Link = 'link',
    Mockup = 'mockup',
    Text = 'text'
}


export interface AttachmentMetadata {
    extension?: string;
    fileKey?: string;
    fileName?: string;
    path?: string;
    size?: number;
    signedUrl?: string;
    disabled?: boolean;
    isError?: boolean;
    fileStatus?: number
    thumbnail?: string;
}

export interface LinkMetadata {
    url: string;
    title?: string;
}

export interface TextMetadata {
    content: string;
}

export interface Block<T = AttachmentMetadata | LinkMetadata | TextMetadata> {
    id: number;
    type: BlockTypes;
    metadata: T;
    uuId?: string;
}

export function isLinkBlock(block: Block): block is Block<LinkMetadata> {
    return block.type === BlockTypes.Link
}
export function isAttachmentBlock(block: Block): block is Block<AttachmentMetadata> {
    return block.type === BlockTypes.Attachment
}

export function isTextBlock(block: Block): block is Block<TextMetadata> {
    return block.type === BlockTypes.Text
}

export interface Contribution {
    keyResult: KeyResult;
    impact?: string;
    actualImpact?: number;
    initiativeImpact?: number;
}

export interface InitiativeOKR {
    okrId: number,
    contributions: Contribution[]
}

export interface Initiative {
    id: number;
    title: string;
    problem: string;
    solution: string;
    validation: string;
    owner: User;
    affectedProducts: Product[];
    status: InitiativeStatus;
    size: string;
    priority: InitiativePriority;
    type: InitiativeType;
    startDate: number | null,
    endDate: number | null,
    releaseDate: number | null,
    blocks: Block[];
    stories: Story[];
    // metrics: Metric[];
    successDefinition?: string;
    products: Product[];
    teams: Team[];
    lastModifiedDate: number,
    statusLastModifiedDate: number,
    key?: string;
    okrs: InitiativeOKR[],
    delayRisk?: NodeRisk | null
}

export interface InitiativeSaveModel extends Omit<Initiative, 'id' | 'owner' | 'affectedProducts' | 'blocks' | 'metrics' | 'products' | 'teams' | 'stories' | 'lastModifiedDate' | 'statusLastModifiedDate'> {
    owner?: number;
    affectedProducts?: number[];
    metrics?: number[];
    products?: number[];
    teams?: number[];
    stories?: StorySaveModel[];
}

export interface Workspace {
    id: number;
    name: string;
}


export interface Metric {
    id: number;
    name: string;
    type: string;
    description: string;
    formula: string;
    uom?: string;
    level: number;
    currentValue: number;
    creator: User | null;
    lastModifiedDate: number;
}

export interface MetricSaveModel extends Omit<Metric, 'id' | 'type' | 'currentValue' | 'creator' | 'lastModifiedDate'> { }


export interface Projection {
    id: number;
    metric: Metric;
    initialValue: number;
    targetValue: number;
}

export interface KeyResult {
    id: number;
    metric: Metric;
    startValue?: number;
    targetValue?: number;
    initiativeCount?: number;
    progress: number;
}

export interface KeyResultSaveModel extends Omit<KeyResult, 'id' | 'metric' | 'initiativeCount' | 'krProgress' | 'lastModifiedDate'> {
    metric: number;
}

export interface OKRProgress {
    allInitiativesCount: number;
    liveInitiativesCount: number;
}

export interface OKR {
    id: number;
    objective?: string;
    startDate?: number;
    endDate?: number;
    initiativeCount?: number;
    initiatives: Initiative[];
    owner?: User;
    keyResults: KeyResult[];
    progress: OKRProgress;
    allKrProgress?: number;
    parentOkr?: OKR;
    teams?: Team[];
}

export interface OKRSaveModel extends Omit<OKR, 'id' | 'progress' | 'initiativeCount' | 'initiatives' | 'keyResults' | 'owner' | 'parentOkr' | 'teams'> {
    owner?: number;
    parentOkr?: number;
    teams?: number[];
}

export interface Story {
    id: number;
    type?: string;
    title?: string;
    description?: string;
    // acceptanceCriteria?: string[];
    // size?: string;
    // storyPoints?: number;
    status?: string;
    key?: string;
    points?: number;
    // design?: Block[];
    issueTrackingNumber?: string;
    blocks: Block<AttachmentMetadata>[];
    dependencyTicket: Dependency[];
}

export interface StorySaveModel extends Omit<Story, 'id' | 'status' | 'issueTrackingNumber' | 'key' | 'blocks' | 'dependencyTicket'> {

}

export interface Phase {
    stories: Story[];
}

export interface IntegrationProperties {
    access_token: string;
    refresh_token?: string;
    scope?: string;
    token_type?: string;
    expires_in?: number;
    url: string;
}

export interface EnabledIntegration {
    id: number;
    name: string;
    integrationProperties: IntegrationProperties
}

export type UserIntegrations = EnabledIntegration[];

export interface JiraIntegrationProps {
    client_id: string;
    redirect_uri: string;
    scope: string;
}

export interface AvailableIntegration {
    id: number;
    name: string;
    properties: JiraIntegrationProps;
}

export interface WorkspaceIntegration {
    name: string;
    integrationProperties: {
        id: string;
        url: string;
    };
    mappings: JiraMapping[] | null;
}

export interface JiraBoard {
    name: string;
    id: number;
}

export interface JiraOption {
    projectKey: string;
    boards: JiraBoard[]
}

export interface JiraIntegrationOption {
    projectKey: string;
    board?: JiraBoard
}


export interface JiraProject {
    id: number;
    key: string;
    name: string;
}

export interface JiraFieldAllowedValue {
    id: number;
    name: string;
}

export interface JiraField {
    key: string;
    name: string;
    required: boolean;
    allowedValues: JiraFieldAllowedValue[] | null;
    schema: any;
    operations: ['REMOVE', 'ADD', 'SET'];
    autoCompleteUrl: string | null;
    options?: any[]
}

export interface JiraIssueType {
    id: number;
    name: string;
    description: string;
    fields: { [key: string]: JiraField };
}

export interface JiraProjectIssueTypes {
    key: string;
    name: string;
    id: number;
    issuetypes: JiraIssueType[]
}

export interface JiraFieldMapping {
    prodmapField: string;
    jiraField: string;
    values?: {
        prodmapValue: string;
        jiraValue: number;
    }[]
}

export interface JiraMapping {
    projectId: number;
    initiativeMapping?: {
        fieldId: number;
        fields: JiraFieldMapping[];
    };
    requirementMapping?: {
        fieldId: number;
        fields: JiraFieldMapping[];
    };
}

export interface JiraStatusCategory {
    id: number;
    key: string;
    name: string;
    colorName: string;
    self: string;
}

export type JiraStatusScope = { type: 'GLOBAL' } | {
    type: 'PROJECT',
    project: {
        id: string,
    }
}

export interface JiraStatusUsage {
    issueTypes: string[];
    project: {
        id: string;
    };
}

export interface JiraStatus {
    id: string;
    name: string;
    description: string;
    untranslatedName: string;
    iconUrl: string;
    self: string;
    statusCategory: JiraStatusCategory;
    scope: JiraStatusScope;
    usages: JiraStatusUsage[];
}

export enum JiraMappingObjectTypes {
    Initiative = 'initiative',
    Requirement = 'requirement'
};

export interface JiraAssignableUserType {
    accountId: string;
    displayName: string;
    emailAddress: string;
    active: boolean;
}

export interface JiraTeam {
    displayName: string;
    teamId: string;
    description: string;
    includesYou: boolean;
    largeAvatarImageUrl: string;
    largeHeaderImageUrl: string;
    memberCount: number;
    organizationId: string;
    smallAvatarImageUrl: string;
    smallHeaderImageUrl: string;
}

export type PreferenceValues<T extends string> = {
    [key in T]?: any
}

export interface Preferences<T extends string> {
    objectId: number | null;
    objectKey: string;
    value: { main: PreferenceValues<T> } | PreferenceValues<T>
}

export enum CalendarPresetsKeys {
    lastQuarter = 'lastQuarter',
    lastMonth = 'lastMonth',
    oneWeek = 'oneWeek',
    twoWeeks = 'twoWeeks',
    oneMonth = 'oneMonth',
    thisMonth = 'thisMonth',
    nextMonth = 'nextMonth',
    thisQuarter = 'thisQuarter',
    nextQuarter = 'nextQuarter',
    endOfQuarter = 'endOfQuarter',
    thisYear = 'thisYear',
    nextYear = 'nextYear'
}

export interface Dependency {
    dueDate: number | null;
    description: string;
    title?: string;
    status: DependencyStatuses;
    notes?: string;
    team: { id: number, name: string };
    id: number;
    requirement: string;
    owner: DependencyOwner;
    initiative: DependencyInitiative;
    resolvingInitiative?: DependencyInitiative;
}

export interface DependencyInitiative {
    id: number;
    title: string;
    owner: DependencyOwner;
    deleted?: boolean;
}

export interface DependencyOwner {
    userName: string;
    fullName: string;
    id: number;
}

export interface DependencySaveModel extends Omit<Dependency, 'id' | 'requirement' | 'owner' | 'initiative' | 'team' | 'resolvingInitiative'> {
    teamId: number,
}

export enum DependencyStatuses {
    Open = 'open',
    Resolved = 'resolved',
    Declined = 'rejected',
    Accepted = 'done'
}

export enum PreferencesKeys {
    product = 'product',
    dependencyTicket = 'dependencyTicket',
    okr = 'okr',
    initiative = 'initiative',
    roadmap = 'roadmap',
    report = 'report',
    idea = 'idea',
    knowledgeBase = 'knowledgeBase',
    userGridLayout = 'userGridLayout',
    teamGridLayout = 'teamGridLayout',
    metricGridLayout = 'metricGridLayout',
    executionView = 'executionView',
    executionViewGridLayout = 'executionViewGridLayout',
    planningAnalyses = 'planningAnalyses',
    roadmapMetrics = 'roadmapMetrics',
    initiativeV2 = 'initiativeV2'
}

export type CommentObjectKey = 'stories' | 'initiatives';
export type CommentFieldKey =
    | 'overview.type'
    | 'overview.priority'
    | 'overview.size'
    | 'overview.problem'
    | 'overview.validation'
    | 'overview.solution'
    | 'description'
    | 'impact.successDefinition';
export type CommentEventCreateSuccessDetail = { temporaryId: string, commentId: Comment['id'] };
export type CommentEventCancelDetail = { temporaryId: string };
export type CommentEventDeleteDetail = { commentId: Comment['id'] };
export type CommentEventSelectDetail = { commentId?: Comment['id'] };
export type CommentEventHighlightDetail = { commentId?: Comment['id'], scroll?: boolean };
export type CommentEventResolveDetail = { commentId: Comment['id'], resolved: boolean };
export type CommentEventCurrentHighLightDetail = { commentId: Comment['id'], remove?: boolean };
export type CommentEventHighLightEditorDetail = { comment: CommentExtended };

declare global {
    interface GlobalEventHandlersEventMap {
        'comment-delete': CustomEvent<CommentEventDeleteDetail>;
        'comment-create-start': CustomEvent;
        'comment-create-success': CustomEvent<CommentEventCreateSuccessDetail>;
        'comment-create-cancel': CustomEvent<CommentEventCancelDetail>;
        'comment-select': CustomEvent<CommentEventSelectDetail>;
        'comment-highlight': CustomEvent<CommentEventHighlightDetail>;
        'comment-highlight-clear': CustomEvent;
        'comment-resolve': CustomEvent<CommentEventResolveDetail>;
        'comment-current-highlight': CustomEvent<CommentEventCurrentHighLightDetail>;
        'highlight-editor': CustomEvent<CommentEventHighLightEditorDetail>;
    }
}

export interface Comment {
    author: User;
    message: string;
    id: number;
    parentId?: number;
    createdDate: number;
    lastModifiedDate: number;
    edited: boolean;
    resolved: boolean;
    seen: boolean;
    replies: CommentExtended[] | null;
}

export interface CommentObjectOptions {
    workspaceId: number;
    objectKey: CommentObjectKey;
    objectId: number;
}

export interface CommentFieldOptions extends CommentObjectOptions {
    fieldKey: CommentFieldKey;
}

export interface CommentExtended extends Comment, Omit<CommentFieldOptions, 'workspaceId'> { }

export interface CommentSaveModel extends Pick<Comment, 'message' | 'parentId'> {
    mentions: number[],
    id?: number,
}

export interface Report {
    id: number,
    createdDate: number,
    lastModifiedDate: number,
    createdBy: User,
    data: {
        teams: ReportData[],
        okrs: ReportData[],
    },
    templateId: number,
    title: string,
    summary: string | null,
    type: ReportVariantTypes,
}

export interface ReportData {
    okrs: ReportOKR[],
    id: number,
    keyName: string,
    initiatives: ReportInitiative[],
    note?: string,
    confidence?: number
}

export interface ReportCreateModel {
    teams?: number[];
    okrs?: number[];
    title: string;
    isChildOkrIncluded?: boolean;
}

export interface ReportSaveModel {
    teamId?: number,
    note?: string,
    metricId?: number,
    okrId?: number,
    confidence?: number,
}

export interface ReportOKR extends Omit<OKR, 'initiatives' | 'parentOkr' | 'initiativeCount' | 'progress' | 'keyResults'> {
    progress: number,
    ownerId: number,
    keyResults: ReportOKRKeyResult[]
}

export interface ReportOKRKeyResult extends Omit<KeyResult, 'metric' | 'initiativeCount' | 'krProgress'> {
    value: number,
    name: string,
    confidence?: number,
}

export interface ReportInitiative {
    id: number,
    title: string,
    status: string,
    startDate: number | null,
    releaseDate: number | null,
    daysInStatus: number,
}

export enum ReportVariantTypes {
    'By Okr' = 'okr',
    'By Team' = 'team',
}
export interface AutogenerateLocationState {
    autoCompleteIdea: string;
    knowledgeContext: boolean;
    okrId?: number;
}

export enum ButtonVariants {
    text = 'text',
    outlined = 'outlined',
    contained = 'contained',
}
export interface IntegrationsType {
    id: number,
    name: string,
    url: string,
    workspace?: boolean,
}
export interface TeamResource {
    userId: number;
    startDate: number;
    endDate: number;
}

export interface TeamResourceResponse {
    daysCount: number;
    endDate: number;
    id: number;
    startDate: number;
    user: {
        fullName: string;
        position: string;
        userId: number;
    };
}

export interface Idea {
    id: number;
    title: string;
    description: string;
    priority: InitiativePriority;
    product?: Product;
    owner: User;
    createdDate: number;
    lastModifiedDate: number
}

export interface IdeaSaveModel extends Omit<Idea, 'id' | 'product' | 'owner' | 'createdDate' | 'lastModifiedDate'> {
    productId: number;
}

export interface InitiativeMockupVersion {
    id: number;
    downloadUrl: string;
    prompt: string;
    latest: boolean;
}

export interface InitiativeMockup {
    id: number;
    createdDate: number;
    lastModifiedDate: number;
    title: string;
    prompt: string;
    versions: InitiativeMockupVersion[];
    description: string;
}

export interface InitiativeMockupCreateModel extends Pick<InitiativeMockup, 'title' | 'prompt'> {
    stories: number[];
}

export type InitiativeMockupEditModel =
    { versionId: number; prompt: string } |
    { versionId: number; isLatest: boolean } |
    { versionId: number; description: string }

export enum SsoProviderType {
    Google = 'Google',
    Microsoft = 'Microsoft',
}

export interface KnowledgeBase {
    id: number;
    title: string;
    owner: Owner;
    status: KnowledgeBaseStatus;
    createdDate: number;
    lastModifiedDate: number;
    blocks: Block[];
    content: string;
}

export enum KnowledgeBaseStatus {
    Draft = 'Draft',
    Published = 'Published',
    Archived = 'Archived',
}

export enum GradingGroup {
    AIFeedback = 'AI Feedback',
    NonRelevantFeedback = 'Non-Relevant Feedbacks'
}

export enum RiskLevel {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Critical = 'Critical'
}

export enum InitiativeScoreKey {
    'problem' = 'Problem Statement is empty',
    'validation' = 'Problem Validation is empty',
    'solution' = 'High Level Solution is empty',
    'product' = 'Product is not selected for the initiative',
    'team' = 'Development Team is not selected',
    'notChangedTimeline' = 'Development timeline is not set',
    'notChangedLive' = 'Live date is not selected for the initiative',
    'okrImpact' = 'Objective is not selected for the initiative',
    'okrMetric' = 'Key Result is not selected for the initiative',
    'successDefinition' = 'Success Definition is empty',
    'userStories' = 'Development Requirements are not provided for the initiative',
    'aiTotal' = 'AI Feedback',
    'aiPoint' = 'AI Point'
}
export interface IInitiativeScore {
    id: keyof typeof InitiativeScoreKey,
    title: InitiativeScoreKey;
    percentage: number,
    childElement?: IInitiativeScore
}
export interface aiGenerate {
    name: string;

    initiativeId?: number,
    component?: string,

    storyId?: number,
    content_only?: boolean,
}

export interface Point {
    id: number,
    content: string,
    isRelevant: boolean,
    recommendation: string,
    title: string
}

export interface Subsection {
    title: string,
    points: Point[],
    subsections: Subsection[]
}

export interface Insight {
    title: string,
    points: Point[] | null,
    subsections: Subsection[] | null,
    score: number
}

export interface IrelevantPoint {
    content: string,
    id: number,
    irrelevanceId: number
    title: string;
}

export type InitiativeScore = {
    [key in InitiativeScoreKey]: number;
};

export interface TrackingStory {
    id: number
    name: string
    status: string
}

export interface JiraStory {
    title: string
    completion: number
    currentStatus: string,
    lastStatusChangeTime: string,
    dueDate: string,
    type: string,
    tasks?: [
        {
            title: string
            status: string
            lastStatusChangeDate: string
            key: string
            type: string
        }
    ]
}

export enum SidebarGroup {
    // VIEWS = 'views',
    MAIN = 'main',
    IDEAS = 'ideas',
    ADMIN = 'admin'
}

export interface SidebarMenuConfig {
    items: SidebarMenuItem[]
    getMenuItems?: (items: SidebarMenuItem[]) => SidebarMenuItem[],
    actionButton?: () => JSX.Element,
    createButton?: () => JSX.Element,
    maxLength?: number,
}

export type SidebarMenu = {
    [key in SidebarGroup]: SidebarMenuConfig
}

export enum SourceTypes {
    NOTION = 'notion',
    GITHUB = 'github',
    JIRA = 'jira'
}

export enum StatusTypes {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Critical = 'Critical'
}

export interface Datasource {
    integrationId?: number,
    input: string,
    type: DatasourceType,
    errorText?: string
}

export enum DatasourceType {
    execution = 'execution',
    plan = 'plan'
}

export interface CreateViewModel {
    name: string,
    datasourceList: { integrationId?: number, input: string, type: DatasourceType }[]
}

export interface LinkNode {
    description: string
    id: number
    key: string
    source: string
    title: string
}

export interface NodeRisk {
    category: StatusTypes,
    id: number,
    value: number,
    reasons: { description: string, title: string }[]
}

export interface Node {
    id: number
    data: {
        issueType: string
        project: string
        id: number
        title: string
        priority: InitiativePriority
        key: string
        startDate: string
        status: string
    },
    progress: number | null,
    executionProgress: number | null,
    linkNodes: LinkNode[]
    type: DatasourceType
    viewId: number | null
    risk: NodeRisk,
    parentId: number
}

export interface Source {
    source: string
    type: DatasourceType
}

export enum AIAnswerTypes {
    TEXT = 'text',
    PRD_PREVIEW = 'prd_preview',
    PRD_PREVIEW_STREAM = 'prd_preview_stream',
    MOCKUP = 'mockup',
    PRD_PREVIEW_REFINEMENT = 'prd_preview_refinement',
    PLAN = 'plan',
    PRD_FIRST_GENERATION = 'prd_first_generation',
    PREVIEW = 'preview',
    MOCKUP_STREAM = 'mockup_stream',
    MOCKUP_PREVIEW = 'mockup_preview'
}

export interface View {
    id: number
    name: string
    createdDate: number
    lastModifiedDate: number
    nodes: Node[]
    sources: Source[]
    risk: NodeRisk
}

export interface AMAUserFeedback {
    type: 'positive' | 'negative',
    content: string
}

export type MockupContentType = {
    content: string;
    type: AIAnswerTypes.MOCKUP;
    refinement?: boolean
};

export type MockupPreviewContentType = {
    content: {
        content: {
            html: string,
            title: string,
            field_generation_id?: string
        },
        field_generation_id: string
    };
    type: AIAnswerTypes.MOCKUP_PREVIEW | AIAnswerTypes.MOCKUP_STREAM;
    refinement?: boolean
};

export type TextContentType = {
    content: string;
    type: AIAnswerTypes.TEXT;
    refinement?: boolean
};

export type PRDMainType = {
    title?: string,
    [key: string]: any
}

export type PRDContentType = {
    content: PRDMainType;
    type: AIAnswerTypes.PRD_PREVIEW | AIAnswerTypes.PREVIEW;
    refinement?: boolean
};

export type PRDStreamContentType = {
    content: PRDMainType;
    type: AIAnswerTypes.PRD_PREVIEW_STREAM;
    refinement?: boolean;
    finish?: boolean;
}

export type PRDRefinementContentType = {
    content: PRDMainType;
    type: AIAnswerTypes.PRD_PREVIEW_REFINEMENT;
    refinement?: boolean
}
export interface GenerationPlan {
    stream_id: string;
    log: string;
    title?: string;
    finish?: boolean;
}
export type PlanContentType = {
    content: GenerationPlan;
    type: AIAnswerTypes.PLAN;
    refinement?: boolean
}

export type PRDFirstGenerationContentType = {
    content: PRDMainType;
    type: AIAnswerTypes.PRD_FIRST_GENERATION;
    refinement?: boolean
}

export enum AMAReferenceTypes {
    KNOWLEDGE = 'knowledge',
    WEBPAGE = 'webpage'
}
export interface KnowledgeReference {
    knowledge_id: number
    items: {
        item_id: number
        distance_score: number
    }[]
    title: string,
    type: AMAReferenceTypes.KNOWLEDGE
}

export interface WebpageReference {
    description: string,
    summarized_content: string,
    title: string,
    url: string,
    type: AMAReferenceTypes.WEBPAGE
}

export type AMAReference = KnowledgeReference | WebpageReference

export type AssistantMessage = {
    feedback?: AMAUserFeedback;
    from: 'assistant';
    text: TextContentType | PRDContentType | PRDStreamContentType | MockupContentType | MockupPreviewContentType | PRDRefinementContentType | PlanContentType | PRDFirstGenerationContentType;
    references: AMAReference[]
    id: number | string
    stream_logs?: string[]
    stream_plan?: GenerationPlan[]
    streaming?: boolean,
    stream_id?: string
};

export type UserMessage = {
    feedback?: AMAUserFeedback;
    from: 'user';
    text: string;
    references: AMAReference[]
    id: number | string
};

export type Message = UserMessage | AssistantMessage;

export interface ChatMessage {
    id: number
    message: Message,
    from: 'user' | 'assistant',
    timestamp: string
    references: AMAReference[]
}

export enum KBTypes {
    GLOSSARY = 'glossary',
    TEAM = 'teams',
    GENERAL = 'general',
    GOALS = 'goals',
    PERSONA = 'persona',
    DEFAULT = 'default',
    PRODUCTS = 'products',
}

export interface KBSource {
    id: number
    source: string,
    type: string
    name: string
}

export interface KBData {
    id: number
    knowledgeBaseId: number;
    createdDate: number
    lastModifiedDate: number,
    name: string
    knowledgeItems: TeamKnowledgeItem | GlossaryKnowledgeItem[]
    datasource: KBSource[]
    type: string
}

export interface TeamKnowledgeItem {
    id: number
    createdDate: number
    lastModifiedDate: number,
    knowledgeBaseId: number
    datasource: KBSource
    data: {
        id: number
        team_lead: string
        team_name: string
    }
}

export interface GlossaryKnowledgeItem {
    id: number
    createdDate: number
    lastModifiedDate: number,
    knowledgeBaseId: number
    datasource: KBSource
    data: {
        id: number
        term: string
        description: string
    }
}

export interface GeneralKnowledgeItem {
    id: number
    createdDate: number
    lastModifiedDate: number,
    knowledgeBaseId: number
    sources: KBSource[]
    type: KBTypes
    name: string
}

export interface ProductKnowledgeItem {
    id: number
    createdDate: number
    lastModifiedDate: number,
    knowledgeBaseId: number
    sources: KBSource[]
    type: KBTypes.PRODUCTS
    data: {
        name: string
        description: string
        features: string[]
        targetAudience: string[]
        competitors: string[]
        marketPosition: string
    }
}

export interface DefaultKnowledgeItem {
    id: number
    createdDate: number
    lastModifiedDate: number,
    datasource: KBSource
    data: {
        type: 'json',
        name: string
        context: string
    }
}

export enum AMAResponseTypes {
    LOG = 'log',
    RESPONSE = 'response',
    METADATA = 'metadata',
    FINISH = 'finish'
}

export interface InitiativeTemplate {
    id: number
    name: string
    template: Template[]
}

export interface Template {
    description: string
    examples: { user: string, assistant: string }[]
    instructions: string
    title: string,
    web_context: boolean
}

export type AnalysisField = {
    original_value: string | null;
    feedback: string;
    suggestions: string;
    examples: string;
    title: string
};

export type AnalysisReport = {
    [key: string]: AnalysisField;
};

export interface RoadmapItem {
    id: number
    title: string,
    owner: string,
    checked: boolean,
    program: string,
    projectLead: string;
    score: number;
    speaker: string;
    team: string;
    feedbackCount: number
    data: AnalysisReport,
    index?: number
}

export enum moveItemDirection {
    top = 'top',
    up = 'up',
    down = 'down',
    bottom = 'bottom',
}

export enum STORAGE_KEYS {
    ID_TOKEN = 'idToken',
    ACCESS_TOKEN = 'accessToken',
    REFRESH_TOKEN = 'refreshToken',
    REDIRECT_TO = 'redirectTo'
}

export interface RiskSummary {
    risk: StatusTypes | 'NotCalculated';
    percentage: number;
    fill: string;
}
export interface RiskCategoryDistribution {
    critical?: number;
    high?: number;
    low?: number;
    medium?: number;
    not_calculated?: number;
}
export interface RoadmapMetrics {
    initiativeMetrics: {
        plannedInitiatives?: number;
        activeInitiatives?: number;
        notStartedInitiatives?: number;
        completedInitiatives?: number;
        plannedCarryOverInitiatives?: number;
        notPlannedCarryoverInitiatives?: number;
        pushedOutInitiatives?: number;
        replanedInitiatives?: number;
    },
    teamMetrics: {
        totalInvolvedPeople?: number;
        activePeople?: number;
    },
    riskCategoryDistribution?: Record<string, number>;
    statusCategoryAggregated?: Record<string, number>;
    plannedInitiatives?: Initiative[];
    activeInitiatives?: Initiative[];
    notStartedInitiatives?: Initiative[];
    completedInitiatives?: Initiative[];
    plannedCarryOverInitiatives?: Initiative[];
    notPlannedCarryoverInitiatives?: Initiative[];
    pushedOutInitiatives?: Initiative[];
    replanedInitiatives?: Initiative[];
}

export interface RoadmapMetricsPayload {
    plannedInitiativesCount?: number;
    activeInitiativesCount?: number;
    notStartedInitiativesCount?: number;
    completedInitiativesCount?: number;
    plannedCarryOverInitiativesCount?: number;
    notPlannedCarryoverInitiativesCount?: number;
    pushedOutInitiativesCount?: number;
    replanedInitiativesCount?: number;
    totalInvolvedPeopleCount?: number;
    activePeopleCount?: number;
    riskCategoryDistribution?: Record<string, number>;
    statusCategoryAggregated?: Record<string, number>;
    plannedInitiatives?: Initiative[];
    activeInitiatives?: Initiative[];
    notStartedInitiatives?: Initiative[];
    completedInitiatives?: Initiative[];
    plannedCarryOverInitiatives?: Initiative[];
    notPlannedCarryoverInitiatives?: Initiative[];
    pushedOutInitiatives?: Initiative[];
    replanedInitiatives?: Initiative[];
    statusCategoryDistribution?: RoadmapStatusCategoryDistribution;
    notPlanedInitiativeAssignees?: PerInitiativeAssignee[];
    planedInitiativeAssignees?: PerInitiativeAssignee[];
}

export interface RoadmapMetricsDailyPayload {
    date: Date,
    plannedInitiativesCount: number,
    notPlanedInitiativesCount: number,
    completedInitiativesCount: number,
    actualPeriod?: boolean
}

export interface RoadmapMetricsDailyData {
    date: string,
    completed: number,
    remaining: number,
    unplanned: number,
    actualPeriod?: boolean
}

export interface RoadmapStatusCategoryDistribution {
    toDo: number,
    canceled: number,
    inProgress: number,
    done: number
}

export interface PerInitiativeAssignee {
    initiative_id: number,
    total_assignees: number,
    active_assignees: number
}

export enum TagType {
    NEGATIVE = 'negative',
    READY = 'ready',
    PAUSED = 'paused',
    IN_PROGRESS = 'inProgress',
    DONE = 'done',
    TESTING = 'testing',
    NATURAL = 'natural',
    WHITE = 'white',
    CAUTION = 'caution'
}

export enum ContentType {
    OWNER = 'owner',
    JIRA_LINK = 'jiraLink',
    DEVELOPMENT = 'development',
    TEXT = 'text',
    TAG = 'tag',
    DATE = 'date',
    INITIATIVE_STATUS = 'status',
    INITIATIVE_TYPE = 'type',
    INITIATIVE_SIZE = 'size',
    INITIATIVE_PRIORITY = 'priority',
}

export interface Requirement {
    title: string;
    description: string;
    owner: string;
    link: { link: string; text: string };
    dueDate: Date;
    development: string;
    priority: string;
    attachments: string[];
}

export interface InitiativeTemplate {
    templateId: number;
    versionId: number;
    templateName: string;
    content: string;
    templateDescription: null | string;
}
