import { CommonButton, Flexbox, IconButtonComponent, Tooltip, Typography } from 'components'
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ArrowRightIcon, PlusIcon, SidebarIcon } from 'components/icons';
import { insightsOpenSelector, isContentMinimizedSelector, sectionsToShowSelector, setContentMinimized, setInsightsOpen } from 'store/initiative-slice';
import { useDispatch, useSelector } from 'react-redux';
import NavigationItem from './NavigationItem';

const classes = classNames.bind(styles);

const TableOfContents = () => {
    const dispatch = useDispatch();

    const [activeSection, setActiveSection] = useState('initiative-project-overview');

    const sections = useSelector(sectionsToShowSelector);

    const insightsOpen = useSelector(insightsOpenSelector);
    const isContentMinimized = useSelector(isContentMinimizedSelector);

    const handleToggleInsights = () => {
        dispatch(setInsightsOpen(!insightsOpen));
    }

    const scrollToSection = (sectionId: string) => {
        setActiveSection(sectionId);
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            // Update URL
            const sectionName = sectionId.replace('initiative-', '');
            window.history.pushState({}, '', `#${sectionName}`);
        }
    };

    // Handle initial URL hash
    useEffect(() => {
        const hash = window.location.hash.replace('#', '');
        if (hash) {
            const sectionId = `initiative-${hash}`;
            scrollToSection(sectionId);
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const sectionElements = document.querySelectorAll('[data-section-id]');
            if (!sectionElements.length) { return; }

            // Find all visible sections
            const visibleSections = Array.from(sectionElements).map(section => {
                const rect = section.getBoundingClientRect();
                const sectionId = section.getAttribute('data-section-id') || '';

                return {
                    id: sectionId,
                    top: rect.top,
                    bottom: rect.bottom,
                    height: rect.height,
                    visiblePercentage: getVisiblePercentage(rect)
                };
            }).filter(section => section.visiblePercentage > 0);

            if (!visibleSections.length) { return; }

            // Sort sections by visible percentage and distance from top
            const bestSection = visibleSections.reduce((best, current) => {
                // If a section is significantly more visible, prefer it
                if (current.visiblePercentage > best.visiblePercentage + 20) {
                    return current;
                }

                // If visibility is similar, prefer the one closer to the top
                if (Math.abs(current.visiblePercentage - best.visiblePercentage) <= 20) {
                    return Math.abs(current.top) < Math.abs(best.top)
                        ? current
                        : best;
                }



                return best;
            });

            setActiveSection(`initiative-${bestSection.id}`);
            // Update URL
            const sectionName = bestSection.id.replace('initiative-', '');
            window.history.pushState({}, '', `#${sectionName}`);
        };

        // Helper function to calculate visible percentage of an element
        const getVisiblePercentage = (rect: any) => {
            const windowHeight = window.innerHeight;

            if (rect.top > windowHeight || rect.bottom < 0) { return 0; }

            const visibleHeight = Math.min(rect.bottom, windowHeight) -
                Math.max(rect.top, 0);

            return (visibleHeight / rect.height) * 100;
        };

        let observer: MutationObserver;

        const setupScrollHandler = () => {
            const container = document.getElementById('initiative-management');
            if (!container) { return; }

            container.addEventListener('scroll', handleScroll);
            // Initial check
            handleScroll();

            // Cleanup previous observer if exists
            if (observer) { observer.disconnect(); }
        };

        // Create an observer instance to watch for DOM changes
        observer = new MutationObserver((mutations, obs) => {
            if (document.getElementById('initiative-management')) {
                setupScrollHandler();
            }
        });

        // Start observing
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });

        // Try initial setup
        setupScrollHandler();

        return () => {
            const container = document.getElementById('initiative-management');
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
            observer.disconnect();
        };
    }, []);

    const handleToggleMinimizeContent = () => {
        dispatch(setContentMinimized(!isContentMinimized));
    }

    return (
        <Flexbox vertical justifyBetween className={classes('initiative-sidePanel', { expanded: !isContentMinimized })} id='initiative-sidePanel'>
            <Flexbox vertical className={classes('initiative-sidePanel-content', 'gap-1')} align={isContentMinimized}>
                <Flexbox justifyBetween align className={classes('initiative-sidePanel-content-header')}>
                    {!isContentMinimized && <Typography className={classes('title')}>Table of Contents</Typography>}
                    <IconButtonComponent onClick={handleToggleMinimizeContent} buttonType='without-background' buttonSize='medium'>
                        <SidebarIcon />
                    </IconButtonComponent>
                </Flexbox>
                <Flexbox vertical className={classes('navigation')}>
                    <Flexbox vertical className={classes('navigation-main')}>
                        {sections?.map(({ title, icon, id, loading }: any) => {
                            const Icon = icon;
                            return (
                                <NavigationItem
                                    key={id}
                                    title={title}
                                    icon={<Icon />}
                                    id={id}
                                    activeSection={activeSection}
                                    scrollToSection={scrollToSection}
                                    isContentMinimized={isContentMinimized}
                                    loading={loading}
                                />
                            )
                        })}
                        {/* TODO: Don't remove: We will need this later */}
                        {/* <NavigationItem
                            title={'Title of the section'}
                            icon={<TextIcon />}
                            isContentMinimized={isContentMinimized}
                            className='new'
                        /> */}
                    </Flexbox>
                </Flexbox>
                {/* TODO: Don't remove: We will need this later */}
                {/* {!isContentMinimized ? (
                    <CommonButton className={classes('add-new-section')} buttonSize='small' buttonType='text' startIcon={<PlusIcon />}>
                        Add New Section
                    </CommonButton>
                ) : (
                    <Tooltip title='Add New Section' placement='right'>
                        <IconButtonComponent className={classes('add-new-section')} buttonSize='medium' buttonType='without-background'>
                            <PlusIcon />
                        </IconButtonComponent>
                    </Tooltip>
                )} */}
            </Flexbox>

            {/* TODO: Don't remove: We will need this later */}
            {/* {!isContentMinimized ? <Flexbox fullWidth vertical className={classes('initiative-sidePanel-content-footer', 'gap-3')} onClick={handleToggleInsights}>
                <Flexbox fullWidth className={classes('initiative-sidePanel-score', 'cursor-pointer')}
                >
                    <Flexbox fullWidth align justifyBetween className={classes('initiative-sidePanel-score-content')}>
                        <Flexbox align className={classes('scoreBox-container')}>
                            <Flexbox justify align className={classes('score')}>
                                {score}
                            </Flexbox>
                            <Flexbox vertical className={classes('score-text-content')}>
                                <Typography className={classes('score-text-title', 'caption-2-regular')}>Insights</Typography>
                                <Typography className={classes('score-text-description', 'caption-2-medium')}>Quality Score</Typography>
                            </Flexbox>
                        </Flexbox>
                        <Flexbox align className={classes('score-icon')}>
                            <ArrowRightIcon />
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </Flexbox> : (
                <Flexbox align className={classes('scoreBox-container', 'cursor-pointer')} onClick={handleToggleInsights}>
                    <Flexbox justify align className={classes('score')}>
                        {score}
                    </Flexbox>
                </Flexbox>
            )} */}
        </Flexbox>
    );
};

export default TableOfContents;