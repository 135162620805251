import { Flexbox } from 'components'
import ContentSectionLayout from '../ContentSectionLayout'
import styles from './styles.module.scss'
import { PaintBrushBroadIcon } from 'components/icons'
import classNames from 'classnames/bind'
import { FC, useEffect, useState } from 'react'
import { mockupsSelector, setSectionToRefine } from 'store/initiative-slice'
import { useDispatch, useSelector } from 'react-redux'
import EmptyView from '../EmptyView'
import Mockup from './Mockup'
import { useWorkspaceId } from 'utils/hooks'
import { useLazyGetBlocksQuery } from 'store/initiative-api'
import { AttachmentMetadata, Block } from 'utils/types'
const classes = classNames.bind(styles)

interface MockupsProps {
}

const Mockups: FC<MockupsProps> = () => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();
    const mockups = useSelector(mockupsSelector);
    const [getBlocks] = useLazyGetBlocksQuery();
    const [blocks, setBlocks] = useState<Block<AttachmentMetadata>[]>([]);

    useEffect(() => {

        const loadBlocks = async () => {
            if (mockups?.length > 0) {
                const res = await getBlocks({ workspaceId: workspaceId, blockIds: mockups.map((mockup: any) => mockup.id) });
               if(res.data) {
                const mockupBlocks: any[] = [];
                res.data.forEach((b: Block<AttachmentMetadata>) => {
                    const m = mockups.find((mockup: any) => mockup.id === b.id);
                    mockupBlocks.push({ ...m, metadata: b.metadata })
                })
                setBlocks(mockupBlocks);
               }
            } else {
                setBlocks([]);
            }
        }

        loadBlocks();
    }, [mockups]);

    return (
        <ContentSectionLayout
            title="Mockups"
            icon={<PaintBrushBroadIcon />}
            aiInteractionText={'Generate With AI'}
            id='initiative-mockups'
            isEmpty={!mockups?.length}
            dataSectionId='mockups'
            onAIAction={() => dispatch(setSectionToRefine({ key: 'mockups', type: 'generate', content: null }))}
        >
            {blocks?.length ? (
                <Flexbox align className={classes('mockups-container')} wrap data-section-id='mockups'>
                    {blocks.map((mockup: any) => (
                        <Mockup key={mockup.id} mockup={mockup} />
                    ))}
                </Flexbox>
            ) : (
                <EmptyView
                    title='Generate Mockups'
                    description='Get visual mockups automatically based on your initiative.'
                    aiActionText='Generate With AI'
                    onAIAction={() => dispatch(setSectionToRefine({ key: 'mockups', type: 'generate', content: null }))}
                />
            )}
        </ContentSectionLayout>
    )
}

export default Mockups