import { CommonButton, Flexbox, IconButtonComponent, Loader, Snackbar, Typography } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { AIAnswerTypes, AMAReferenceTypes, AssistantMessage, GenerationPlan, Message, WebpageReference } from 'utils/types';
import { ArrowOutSimpleIcon, FileText, TickRoundedIcon } from 'components/icons';
import WebPageReferencesPanel from 'pages/Assistant/components/AMAMessage/WebPageReferencesPanel';
import SidePanelDrawer from 'components/SidePanelDrawer';
import KnowledgeReferencesPanel from 'pages/Assistant/components/AMAMessage/KnowledgeReferencesPanel';
import { camelCaseToNormal } from 'utils/string';
import { getKBItemFromAMA } from 'pages/Assistant/assistant.api';
import { useDispatch, useSelector } from 'react-redux';
import { referencesDataSelector } from 'store/knowledgeBase-slice';
import { referencesDataLoadingSelector } from 'store/knowledgeBase-slice';
import FeedbackConversation from './FeedbackConversation';
import FullMessageContent from './FullMessageContent';
import { useState } from 'react';

const classes = classNames.bind(styles);

interface MessageContentProps {
    message: Message;
    showFeedbackConversation: boolean;
    feedbackStatus: 'initial' | 'pending' | 'success' | null;
    closeFeedbackConversation: () => void;
    handleSendFeedback: (feedbackType: 'positive' | 'negative') => void;
    streaming?: boolean;
    handleInsertPRD: (message: AssistantMessage) => void;
    setIsOpen?: (isOpen: boolean) => void;
    isOpen?: boolean;
    expandView?: boolean;
    isLoading?: boolean;
    isLatestMessage?: boolean;
}

const MessageContent = ({
    message,
    showFeedbackConversation,
    feedbackStatus,
    closeFeedbackConversation,
    handleSendFeedback,
    streaming,
    handleInsertPRD,
    setIsOpen,
    isOpen,
    expandView,
    isLoading,
    isLatestMessage,
}: MessageContentProps) => {
    const dispatch = useDispatch();

    const referencesData = useSelector(referencesDataSelector)
    const referencesDataLoading = useSelector(referencesDataLoadingSelector)

    const [successInsertPRD, setSuccessInsertPRD] = useState('');
    const [citationsExpanded, setCitationsExpanded] = useState(false);
    const [selectedCitation, setSelectedCitation] = useState<number | null>(null);

    const onGetKBSource = async (kbId: number, kbItemIds: number[]) => {
        try {
            await dispatch(getKBItemFromAMA({ kbId, data: { items: kbItemIds } }))
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Flexbox vertical className={classes('message-box')}>
            <Flexbox vertical className={classes('message-box-content')}>
                {message.from === 'user' ? (
                    <Flexbox justifyBetween className={classes('message-box-content-user-message')}>
                        <Typography className={classes('title', 'caption-2-regular')}>
                            {message.text}
                        </Typography>
                    </Flexbox>
                ) : (
                    <Flexbox vertical className={classes('gap-1', 'message-box-content-assistant', { 'loading': isLoading && isLatestMessage })}>

                        {
                            !!message.stream_logs?.length && (
                                <Flexbox vertical className={classes('assistant-logs', 'generation-box', { 'loading': isLoading && isLatestMessage })}>
                                    <Flexbox vertical className={classes('assistant-logs-content', 'gap-3')}>
                                        <Typography className={classes('paragraph-15-bold')}>AI At Work</Typography>
                                        {message.stream_logs?.map((log: string, index: number) => {
                                            const isLastItem = index === (message.stream_logs?.length ?? 0) - 1;
                                            const showSpinner = isLoading && isLastItem && !message.stream_plan?.length;
                                            return (
                                                <Flexbox
                                                    key={`${log}-${index}`}
                                                    align
                                                    justifyBetween
                                                    className={classes('assistant-logs-item', 'gap-2', 'animate-item')}
                                                    style={{ animationDelay: `${index * 0.05}s` }}>
                                                    <Typography className={classes('assistant-logs-item-message', { 'finished': !isLoading || (index < (message.stream_logs?.length || 0) - 1) })}>{log}</Typography>
                                                    {isLatestMessage && (
                                                        <Flexbox align justify className={classes('assistant-logs-item-icon', 'animate-icon')}>
                                                            {showSpinner ? (
                                                                <Loader size={16} className="loader" />
                                                            ) : (
                                                                <TickRoundedIcon />
                                                            )}
                                                        </Flexbox>
                                                    )}
                                                </Flexbox>
                                            );
                                        })}
                                    </Flexbox>
                                </Flexbox>
                            )
                        }

                        <Flexbox vertical className={classes('final-content-container')}>
                            {
                                !!message.stream_plan?.length && (
                                    <Flexbox vertical className={classes('generation-plan', 'generation-box', 'gap-2', { 'loading': isLoading && isLatestMessage })}>
                                        {message.text.type !== AIAnswerTypes.TEXT && (
                                            <Typography className={classes('paragraph-15-bold')}>Sections</Typography>
                                        )}
                                        <Flexbox vertical className={classes('generation-plan-content', 'gap-3')}>
                                            {message.stream_plan?.map((plan: GenerationPlan, index: number) => (
                                                <Flexbox
                                                    key={`${plan.stream_id}-${index}`}
                                                    align
                                                    justifyBetween
                                                    className={classes('generation-plan-item', 'gap-2', 'animate-item')}
                                                    style={{ animationDelay: `${index * 0.05}s` }}>
                                                    <Typography className={classes('generation-plan-item-title', 'caption-2-medium', { 'finished': plan.finish || !isLoading })}>
                                                        {camelCaseToNormal(plan.log || plan.title || '')}
                                                    </Typography>

                                                    {plan.finish || !isLoading ? (
                                                        <Flexbox align justify className={classes('generation-plan-item-icon', 'animate-icon')}>
                                                            <TickRoundedIcon />
                                                        </Flexbox>
                                                    ) : (
                                                        <Flexbox align justify className={classes('generation-plan-item-icon', 'animate-icon')}>
                                                            <Loader size={16} className="loader" />
                                                        </Flexbox>
                                                    )}
                                                </Flexbox>
                                            ))}
                                        </Flexbox>
                                    </Flexbox>
                                )
                            }

                            {message.text.content && (
                                <Flexbox vertical justifyBetween className={classes(
                                    'message-box-content-assistant-message',
                                    { 'loading': isLoading && isLatestMessage },
                                    { 'content-ready': !isLoading && isLatestMessage },
                                    { 'with-references': !!message?.references?.length || (message.text.type === AIAnswerTypes.PRD_PREVIEW_REFINEMENT || message.text.type === AIAnswerTypes.PRD_PREVIEW || message.text.type === AIAnswerTypes.MOCKUP_PREVIEW) }
                                )}>
                                    <Flexbox vertical className={classes('message-content-wrapper')}>
                                        <FullMessageContent
                                            message={message as AssistantMessage}
                                            className={classes('minimized-message', { 'shadow-gradient': (message.text.type === AIAnswerTypes.TEXT ? message.text.content.length > 100 : true) && !isLoading && message.from === 'assistant' })}
                                            streaming={streaming}
                                            chatView={true}
                                        />
                                        {(expandView && message.from === 'assistant' &&
                                            !(message.text.type === AIAnswerTypes.TEXT &&
                                                typeof message.text.content === 'string' &&
                                                message.text.content.length < 100)) && (
                                            <button
                                                className={classes('show-more-button')}
                                                onClick={() => { setIsOpen && setIsOpen(!isOpen) }}
                                            >
                                                Show more
                                            </button>
                                        )}
                                    </Flexbox>
                                    {!!message?.references?.length && !isLoading && (
                                        <Flexbox vertical className={classes('citations-container')}>
                                            <div
                                                className={classes('citations-toggle')}
                                                onClick={() => setCitationsExpanded(!citationsExpanded)}
                                            >
                                                Citations ({message.references.length})
                                                <span style={{ fontSize: '10px', marginLeft: '2px' }}>
                                                    {citationsExpanded ? '▲' : '▼'}
                                                </span>
                                            </div>
                                            <Flexbox vertical className={classes('citations-list', { 'expanded': citationsExpanded })}>
                                                {message.references.map((ref, index) => (
                                                    <div
                                                        key={`${ref.type === AMAReferenceTypes.KNOWLEDGE ? ref.knowledge_id : index}-${index}`}
                                                        className={classes('citation-link')}
                                                        onClick={() => {
                                                            if (ref.type === AMAReferenceTypes.KNOWLEDGE) {
                                                                onGetKBSource(ref.knowledge_id, ref.items.map(el => el.item_id));
                                                            }
                                                            setSelectedCitation(index);
                                                        }}
                                                    >
                                                        <span className={classes('citation-number')}>{index + 1}</span>
                                                        <span className={classes('citation-title')}>{ref.title}</span>
                                                    </div>
                                                ))}
                                            </Flexbox>
                                            {selectedCitation !== null && message.references[selectedCitation] && (
                                                <SidePanelDrawer
                                                    anchor="right"
                                                    actionElement={(props) => <div style={{ display: 'none' }} {...props} />}
                                                    paperClassName={classes('citation-panel')}
                                                    open={selectedCitation !== null}
                                                    onClose={() => setSelectedCitation(null)}
                                                >
                                                    {message.references[selectedCitation].type === AMAReferenceTypes.WEBPAGE ? (
                                                        <WebPageReferencesPanel
                                                            title={message.references[selectedCitation].title}
                                                            data={{
                                                                description: (message.references[selectedCitation] as WebpageReference).description,
                                                                summarized_content: (message.references[selectedCitation] as WebpageReference).summarized_content,
                                                                url: (message.references[selectedCitation] as WebpageReference).url,
                                                            }}
                                                        />
                                                    ) : (
                                                        <KnowledgeReferencesPanel
                                                            title={'Sources'}
                                                            loading={referencesDataLoading}
                                                            data={referencesData.map(ref => ({ ...ref.data, createdDate: ref.createdDate, lastModifiedDate: ref.lastModifiedDate }))}
                                                        />
                                                    )}
                                                </SidePanelDrawer>
                                            )}
                                        </Flexbox>
                                    )}
                                    {(message.text.type === AIAnswerTypes.PRD_PREVIEW_REFINEMENT ||
                                      message.text.type === AIAnswerTypes.PRD_PREVIEW ||
                                      message.text.type === AIAnswerTypes.MOCKUP_PREVIEW) &&
                                      !isLoading && (
                                        <Flexbox align fullWidth className={classes('insert-action-button')}>
                                            <CommonButton
                                                buttonType='action'
                                                buttonSize='small'
                                                onClick={() => {
                                                    handleInsertPRD(message)
                                                    setSuccessInsertPRD('PRD Inserted Successfully')
                                                }}
                                            >
                                                <Typography className={classes('caption-2-bold')}>Insert</Typography>
                                            </CommonButton>
                                        </Flexbox>
                                    )}
                                </Flexbox>
                            )}
                        </Flexbox>
                    </Flexbox>
                )}

            </Flexbox>

            {
                showFeedbackConversation && (
                    <FeedbackConversation
                        feedbackStatus={feedbackStatus}
                        handleSendFeedback={handleSendFeedback}
                        closeFeedbackConversation={closeFeedbackConversation}
                    />
                )
            }

            <Snackbar open={!!successInsertPRD} onClose={() => { setSuccessInsertPRD('') }} type='success' >
                <Flexbox vertical align justify>
                    {successInsertPRD}
                </Flexbox>
            </Snackbar>
        </Flexbox>
    )
}

export default MessageContent;