import { FC, Fragment, useEffect, useState } from 'react'
import ContentSectionLayout from '../ContentSectionLayout'
import { Crosshair, CustomDeleteIcon } from 'components/icons'
import { Checkbox, Flexbox, IconButtonComponent, TextField, Typography, ConfirmationDialog } from 'components'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Paper, Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { editModeSelector, liveDateSelector, okrsSelector, setEditMode, setOkrField, setOkrs, setSectionToRefine } from 'store/initiative-slice'
import EmptyView from '../EmptyView';
import { okrsSelector as okrsDataSelector } from 'store/okrs';
import { getOkrs } from 'pages/Okrs/okrs.api';
import { OKR, InitiativeOKR } from 'utils/types';
import SearchableSelect from 'components/SearchableSelect';

const classes = classNames.bind(styles);

interface OkrImpactProps {
}

const columns = ['Key Results', 'Current', 'Target', 'Initiative Impact'];

const OkrImpact: FC<OkrImpactProps> = () => {
    const dispatch = useDispatch();

    const editMode = useSelector(editModeSelector);
    const okrs = useSelector(okrsSelector);

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [itemToRemove, setItemToRemove] = useState<InitiativeOKR | null>(null);
    const [filteredOkrs, setFilteredOkrs] = useState<OKR[]>([]);

    const releaseDate = useSelector(liveDateSelector);

    // Get all okrs from the store
    const okrsData = useSelector(okrsDataSelector);

    useEffect(() => {
        dispatch(getOkrs());
    }, [])

    useEffect(() => {
        if (okrsData.length) {
            const filterByDate = okrsData.filter(okr => {
                const okrEndDate = okr.endDate ? new Date(okr.endDate) : null

                if (okrEndDate) {
                    okrEndDate.setHours(okrEndDate.getHours() + 24);
                }

                if (!releaseDate || !okrEndDate || releaseDate <= okrEndDate) {
                    return okr
                }
            })
            setFilteredOkrs(filterByDate)
        }
    }, [okrsData, releaseDate])

    const handleAIAction = (sectionId: string, type: 'generate' | 'refine', content: any) => {
        dispatch(setSectionToRefine({ key: sectionId, type: type, content: content }));
    }

    const onSelectOkr = (value: number[]) => {
        dispatch(setEditMode(true));
        const newOkrs = value.map(okr => {
            const exsOkr = okrs.find((pOkr: InitiativeOKR) => pOkr.okrId === okr);

            if (exsOkr) {
                return exsOkr;
            } else {
                return {
                    okrId: okr,
                    contributions: okrsData.find((el: OKR) => el.id === okr)?.keyResults.map(kr => ({
                        keyResult: kr,
                        checked: true
                    }))
                };
            }
        });
        dispatch(setOkrs(newOkrs));
    };

    const handleConfirmDelete = () => {
        if (itemToRemove) {
            dispatch(setOkrs(okrs.filter((okr: InitiativeOKR) => okr.okrId !== itemToRemove.okrId)));
            setOpenDeleteConfirmation(false);
            setItemToRemove(null);
        }
    };

    const handleCancelDelete = () => {
        setOpenDeleteConfirmation(false);
        setItemToRemove(null);
    };

    return (
        <ContentSectionLayout
            title="Okr Impact"
            icon={<Crosshair />}
            // aiInteractionText={'Match OKR'}
            id='initiative-impact'
            isEmpty={okrs.length === 0}
            dataSectionId='impact'
        // onAIAction={() => handleAIAction('impact', 'refine', okrs)}
        >
            {okrs.length === 0 ? (
                <EmptyView
                    title='Set your OKRs'
                    description='Define clear objectives and key results to measure success and track progress.'
                    // aiActionText='Match OKR'
                    // onAIAction={() => handleAIAction('impact', 'generate', okrs)}
                    customActions={
                        <>
                            <SearchableSelect
                                options={filteredOkrs.map(okr => ({ label: okr.objective || '', value: okr.id }))}
                                value={okrs.map((okr: any) => okr.okrId)}
                                onSelect={(value) => onSelectOkr(value)}
                                title={'Affected OKR (Filtered by live date)'}
                                placeholder={'Select From List'}
                                multiSelect={true}
                                position='center'
                            />
                        </>
                    }
                />
            ) : (
                <Flexbox vertical className={classes('okr-impact-container')} data-section-id='okr-impact'>
                    {
                        okrs.map((okr: InitiativeOKR, index: number) => (
                            <Fragment key={index}>
                                <Flexbox justifyBetween align className={classes('okr-impact-container-header')}>
                                    <Typography className={classes('title')}>{okrsData.find(fOkr => fOkr.id === okr.okrId)?.objective}</Typography>
                                    {editMode && (
                                        <IconButtonComponent buttonSize='small' buttonType='with-background'
                                            onClick={() => {
                                                setOpenDeleteConfirmation(true);
                                                setItemToRemove(okr);
                                            }}
                                        >
                                            <CustomDeleteIcon />
                                        </IconButtonComponent>
                                    )}
                                </Flexbox>
                                {okr.contributions?.length > 0 && (
                                    <TableContainer component={Paper} className={classes('okr-impact-container-table')}>
                                        <MuiTable aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell key={column}>{column}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {okr.contributions.map((row: any) => (
                                                    <TableRow
                                                        key={row.keyResult.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <Flexbox justifyBetween align>
                                                                {editMode && (
                                                                    <Checkbox
                                                                        className={classes('checkbox')}
                                                                        size='small'
                                                                        checked={row.checked}
                                                                        onChange={(e) => {
                                                                            dispatch(setOkrField({ id: okr.okrId, krId: row.keyResult.id, field: 'checked', value: !row.checked }))
                                                                        }}
                                                                    />
                                                                )}
                                                                {row.keyResult.metric.name}
                                                            </Flexbox>
                                                        </TableCell>
                                                        <TableCell>{row.keyResult.metric.uom} {row.keyResult.metric.currentValue}</TableCell>
                                                        <TableCell>{row.keyResult.metric.uom} {row.keyResult.targetValue}</TableCell>
                                                        {editMode ? (
                                                            <TableCell>
                                                                <TextField
                                                                    type='number'
                                                                    className={classes('text-field')}
                                                                    onChange={(e) => {
                                                                        dispatch(setOkrField({ id: okr.okrId, krId: row.keyResult.id, field: 'initiativeImpact', value: e.target.value }))
                                                                    }}
                                                                    value={row.initiativeImpact}
                                                                />
                                                            </TableCell>
                                                        ) : (
                                                            <TableCell>{row.initiativeImpact}</TableCell>
                                                        )}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </MuiTable>
                                    </TableContainer>
                                )}
                            </Fragment>
                        ))
                    }
                    {editMode && (
                        <Flexbox justifyBetween align fullWidth>
                            <SearchableSelect
                                options={filteredOkrs.map(okr => ({ label: okr.objective || '', value: okr.id }))}
                                value={okrs.map((okr: any) => okr.okrId)}
                                onSelect={(value) => onSelectOkr(value)}
                                title={'Affected OKR (Filtered by live date)'}
                                multiSelect={true}
                                componentType='Button'
                                buttonText='Choose OKR'
                                fullWidth
                                position='center'
                            />
                        </Flexbox>
                    )}
                </Flexbox>
            )}
            <ConfirmationDialog
                open={openDeleteConfirmation}
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                confirmButtonStyle='danger'
                title='Remove this OKR?'
            >
                <Flexbox>
                    Are you sure you want to remove this OKR from this section?
                </Flexbox>
            </ConfirmationDialog>
        </ContentSectionLayout>
    )
}

export default OkrImpact;